<template>
  <v-container class="my-12">
    <h2 :class="`head-title${$vuetify.breakpoint.xs ? '-mobile' : ''} grey--text text--darken-3`">Experiences</h2>
    <h2 :class="`head-title${$vuetify.breakpoint.xs ? '-mobile' : ''} grey--text text--darken-3`">
      Introduction,Articles &amp; Projects
    </h2>
    <v-row class="mt-2">
      <v-col class="py-0" v-for="(col, colIndex) in infoCards" :key="colIndex" cols="12" md="6">
        <v-hover v-for="(item, index) in col" :key="index" v-slot="{ hover }">
          <v-card class="mt-5" :to="item.to">
            <div class="overflow-hidden">
              <v-img
                gradient="to top, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                :class="{ 'card-image-on-hover': hover, 'card-image': true }"
                :src="item.imageSrc"
              >
              </v-img>
            </div>
            <v-btn disabled text absolute top right :ripple="false">
              <div>
                <strong :class="`card-title${$vuetify.breakpoint.xs ? '-mobile' : ''} white--text`">
                  {{ item.title }}
                </strong>
                <br />
                <strong :class="`card-subtitle${$vuetify.breakpoint.xs ? '-mobile' : ''} white--text`">
                  {{ item.subtitle }}
                </strong>
              </div>
            </v-btn>
            <v-btn v-if="$vuetify.breakpoint.smAndUp" disabled text absolute bottom left :ripple="false">
              <strong class="white--text"> {{ item.label }} </strong>
            </v-btn>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { imageBaseURL } from '@/settings'
export default {
  name: 'Home',

  data: () => {
    const baseUrl = imageBaseURL + '/illustrations/'
    return {
      // for navigation cards' contents
      infoCards: [
        [
          {
            title: '教育经历',
            subtitle: 'Educational experience',
            label: '本科学历，来自西南大学',
            imageSrc: baseUrl + '00001.jpg',
            to: '/experiences'
          },
          {
            title: '项目一览',
            subtitle: 'List of Projects',
            label: '主职前端开发',
            imageSrc: baseUrl + '00002.jpg',
            to: '/projects'
          }
        ],
        [
          {
            title: '关于我',
            subtitle: 'Introduction',
            label: '各种意义上的颜控',
            imageSrc: baseUrl + '00003.jpg',
            to: '/introduction'
          },
          {
            title: '文章分享',
            subtitle: 'Articles',
            label: '简单经历分享',
            imageSrc: baseUrl + '00004.jpg',
            to: '/articles'
          }
        ]
      ]
    }
  },

  watch: {},

  computed: {},

  methods: {}
}
</script>

<style scoped>
.head-title {
  font-size: 3em;
}

.head-title-mobile {
  font-size: 2em;
}

.card-title {
  font-size: 2em;
}

.card-title-mobile {
  font-size: 1.8em;
}

.card-subtitle {
  font-size: 0.8em;
}

.card-subtitle-mobile {
  font-size: 0.7em;
}

.card-image-on-hover {
  transform: scale(1.2);
}

.card-image {
  transition: transform 0.4s ease-in-out;
}
</style>
